import React, { useCallback, useEffect, useRef, useState } from "react";
import Router from "next/router";
import { useRouter } from "next/router";

import { useRulesData, useDiscoverData } from "../../utils/apollo/reactivities";
import { CircularProgressIndicator } from "./CircularProgressIndicator/CircularProgressIndicator";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../hooks/useUser";
import { setAuthorization } from "../../utils/redux/actionCreators/actions";

export const RouteGuard = ({ children, authProtected = false }) => {
  const previousPathname = useRef(null);
  const router = useRouter();
  // const { isAuthorized, isReady, isLoading: isUserLoading, redirectTo, discoverClubRequired } = useAuth();
  const {
    query: { club },
  } = router;

  const dispatch = useDispatch();

  // const setHeader = useSelector(({ authorization }) => authorization.setHeader);
  // const showAuthorization = useSelector(({ authorization }) => authorization.showAuthorization);

  const {
    isAuthorized,
    // isLoading: isUserLoading,
    isReady,
    rulesError,
    discoverClubRequired,
  } = useUser({
    skip: !club, // Пропускаем, если параметр `club` отсутствует
  });
  const isRulesAccepted = useRulesData();
  const isDiscoverAccepted = useDiscoverData();

  // const [isNavigationLoading, setIsNavigationLoading] = useState(false);

  const [{ authorized, isLoading }, setState] = useState({
    authorized: false,
    isLoading: true,
  });

  useEffect(() => {
    // Функция обработки событий маршрутизации

    if (!isReady) return;

    handleAuthCheck(router.asPath);
    const handleRouteChangeStart = (url) => {
      // if (isUserLoading) {
      //   // Если авторизация еще не завершена, отменяем маршрут
      //   Router.events.emit("routeChangeError");
      //   setIsNavigationLoading(false);
      //   return; // Прерываем выполнение
      // }
      const path = url.split("?")[0];

      // previousPathname = path;
      if (previousPathname.current && previousPathname.current !== path && authProtected) setState({ authorized: false, isLoading: true });
      // setIsNavigationLoading(true); // Показ загрузочного индикатора при смене маршрута
    };

    // const handleRouteChangeComplete = (url) => {
    //   setIsNavigationLoading(false); // Скрываем индикатор после завершения перехода
    //   handleAuthCheck(url); // Проверка прав доступа к новому маршруту
    // };

    // const handleRouteChangeError = () => {
    //   setIsNavigationLoading(false); // Скрываем индикатор загрузки при отмене маршрута
    // };

    // Подписка на события маршрутизации
    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleAuthCheck);
    // Router.events.on("routeChangeError", handleRouteChangeError);
    // Очистка событий маршрутизации
    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleAuthCheck);
      // Router.events.off("routeChangeError", handleRouteChangeError);
    };

    //isUserLoading
  }, [authProtected, isReady, isAuthorized, isRulesAccepted, isDiscoverAccepted, discoverClubRequired]); // setHeader, showAuthorization,

  // useEffect(() => {
  //   // Проверяем авторизацию один раз при первой загрузке (не отслеживаем изменения router.asPath)
  //   if (isReady) handleAuthCheck(router.asPath); // Выполняется только один раз, при завершении загрузки AuthProvider
  // }, [isReady]); // Зависим только от флага isReady

  const handleAuthCheck = (asPath) => {
    const { pathname, query } = extractPathAndQuery(asPath);

    if (previousPathname.current !== pathname) previousPathname.current = pathname;

    const club = getClubName(pathname);

    const nextUrl = getNextUrl(pathname, query);

    // Если пользователь не авторизован

    const clubPagePath = `/club/${club}`;

    if (!isAuthorized) {
      if (authProtected) {
        // if ((previousPathname.current == pathname) == clubPagePath && !nextUrl) return;

        redirectTo(clubPagePath, nextUrl); //pathname

        // redirectTo(`/club/${clubName}?nextUrl=${pathname}`);
        dispatch(setAuthorization());
        return;
      }

      return setState({ authorized: false, isLoading: false }); // Если авторизация не требуется, ничего не делаем
    }

    if (!club) {
      if (authProtected) {
        redirectTo(`/`);
        return;
      }

      return setState({ authorized: true, isLoading: false });
    }

    if (club && isRulesAccepted === null) return;

    const rulesPath = `${clubPagePath}/rules`;

    if (pathname !== rulesPath && !isRulesAccepted) {
      redirectTo(rulesPath, nextUrl);
      return;
    }

    // Проверяем, нужно ли перенаправление на "выявление" (discover)
    const discoverPath = `${clubPagePath}/discover`;

    // oldPreviousPathname != discoverPath &&

    //(!setHeader || !showAuthorization) &&
    if (pathname !== discoverPath && isRulesAccepted && discoverClubRequired && !isDiscoverAccepted) {
      redirectTo(discoverPath, nextUrl);
      return;
    }

    return setState({ authorized: true, isLoading: false });
  };

  const extractPathAndQuery = useCallback((url) => {
    const [pathname, queryString = ""] = url.split("?");
    const query = Object.fromEntries(new URLSearchParams(queryString));
    return { pathname, query };
  }, []);

  const getClubName = useCallback((pathname) => {
    const segments = pathname.split("/").filter(Boolean);
    const clubIndex = segments.indexOf("club");
    return clubIndex !== -1 ? segments[clubIndex + 1] : null;
  }, []);

  const redirectTo = useCallback((pathname, nextUrl) => {
    // setIsNavigationLoading(true);
    // Показ индикатора загрузки при редиректе

    const query = nextUrl ? { nextUrl } : {};

    Router.replace({ pathname, query }, pathname);

    // .finally(() => setIsNavigationLoading(false));
    // router.replace(url).finally(() => setIsNavigationLoading(false));
  }, []);

  const getNextUrl = useCallback((pathname, query) => {
    if (!query) return null;

    const queryString = new URLSearchParams(query).toString(); // Преобразуем объект с параметрами в строку запроса
    return queryString ? `${pathname}?${queryString}` : null;
    // return ` ${pathname}${queryString ? `?${queryString}` : ""}`; // Комбинируем путь и строку запроса
  }, []);

  // Показываем глобальный индикатор загрузки, если приложение еще не готово
  // if (isUserLoading || isNavigationLoading || !isReady) {
  //   return <CircularProgressIndicator fullscreen />;
  // }

  // // Если маршрут защищен, но пользователь не авторизован, ничего не показываем
  // if (authProtected && !isAuthorized) {
  //   return null;
  // }

  // // Рендерим дочерние компоненты после успешной проверки
  // return <>{children}</>;

  if (isLoading) return <CircularProgressIndicator fullscreen isPortal />;
  return (!authProtected || authorized) && children;
};
